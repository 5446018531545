/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import cuteCouple from '../../images/people/couple-with-computer.png'
import umbrellaCouple from '../../images/people/umbrella-couple.png'

import grandKids from '../../images/people/grandkids.png'

import headsetLady from '../../images/people/headset-lady.png'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/QuestionsHeader'
import footer from '../../components/Footer'
import GlobalBanner from '../../components/GlobalBanner'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-indexlp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Do you know when Medicare Annual Enrollment is? | Healthcareplans.com',
          description:
            "Get the information you need on Medicare Annual Enrollment for Medicare Advantage and other plans. At Healthcareplans.com, we're here to help you navigate your Medicare journey.",
          canonical: 'https://healthcareplans.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '169379',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Do you know when Medicare Annual Enrollment is? | Healthcareplans.com',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(brandyList, data.allBrandyDisclaimer.edges),
    main: (
      <>
        <Helmet />
        <VariableContent
          backgroundColor="dark"
          className="text-align-center-desktop"
        >
          <Typography variant="h1" style={{ color: '#ffffff' }}>
            Medicare Annual Enrollment Period 101
          </Typography>
          <Typography
            variant="h4"
            style={{ color: '#ffffff', marginBottom: '0' }}
          >
            When is the Medicare Annual Enrollment Period? Get answers to this
            and other most-asked Medicare Annual Enrollment Period questions,
            including how plan types like Medicare Advantage and Medigap are
            involved.
          </Typography>
        </VariableContent>

        <SplitContent
          backgroundColor="white"
          image={<img src={grandKids} alt="grandparents and grandkids" />}
          mainContent={
            <>
              <Typography variant="h2">
                Medicare Annual Enrollment Period Takeaways
              </Typography>
              <List>
                <ListItem className="leshen-list-item">
                  The Medicare Annual Enrollment Period (AEP)  occurs from
                  October 15 through December 7 yearly.
                </ListItem>
                <ListItem className="leshen-list-item">
                  Changes made to Medicare coverage during AEP take effect on
                  January 1 of the following year.
                </ListItem>
                <ListItem className="leshen-list-item">
                  During AEP, you can enroll in Medicare Advantage (Part C)
                  plans for the first time or change existing coverage
                </ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          alignMainContent="left"
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="body" style={{ marginBottom: '40px' }}>
                Healthcareplans.com is your resource for Medicare information.
                We’re here to make the process of Medicare enrollment simpler by
                providing all the information you need about Medicare’s various
                enrollment periods. This article focuses on the Medicare Annual
                Enrollment Period (AEP), including when AEP occurs each year,
                what you can do during AEP, and how it is different from the
                Open Enrollment Period which it is sometimes confused with.
              </Typography>
              <Typography variant="h2">
                What is the Medicare Annual Enrollment Period (AEP)?
              </Typography>
              <Typography variant="body" style={{ marginBottom: '40px' }}>
                The Medicare Annual Enrollment Period (AEP) occurs each year
                from October 15 through December 7. During this time, Medicare
                enrollees can review and make changes to their Medicare coverage
                which will go into effect on January 1 of the following year.
              </Typography>
              <Typography variant="h2">
                What is the Medicare Open Enrollment Period (OEP)?
              </Typography>
              <Typography variant="body" style={{ marginBottom: '40px' }}>
                The term Open Enrollment Period is often used interchangeably
                with the Annual Enrollment Period, but the OEP (sometimes known
                as the Medicare Advantage Open Enrollment Period or MAOEP) is
                actually an entirely different period. OEP lasts from January 1
                to March 1 each year; this period is for switching Medicare
                Advantage plans or for leaving Medicare Advantage and switching
                to Original Medicare. This period exists so that Medicare
                Advantage enrollees can make a final check of their impending
                year’s coverage and make a small change; it should not be
                treated as a time for enrolling in a new plan.
              </Typography>
              <Typography variant="h2">
                What is the difference between AEP and OEP?
              </Typography>
              <Typography variant="body" style={{ marginBottom: '40px' }}>
                Not only do the Medicare Annual Enrollment Period (AEP) and the
                Medicare Open Enrollment Period (OEP) occur at different
                times—AEP from October 15 to December 7 each year, OEP from
                January 1 to March 31—they serve different purposes. OEP is for
                making changes to existing Medicare Advantage coverage, while
                AEP is for more comprehensive Medicare enrollment.
              </Typography>
              <Typography variant="h2">What can I do during AEP?</Typography>
              <Typography variant="body">
                During AEP you can change your existing coverage or enroll in a
                Medicare Advantage plan for the first time. AEP is the perfect
                time to shop around for different plans to find the coverage
                that best fits for your needs—and your budget. You can work
                directly with an insurance provider, or use an insurance agency
                like healthcareplans.com to help you find the coverage you need.
                <br />
                <br />
                During AEP, you can sign up for the first time or switch
                coverage involving the following types of plans:
              </Typography>
              <List>
                <ListItem className="leshen-list-item">
                  Medicare Advantage (Medicare Part C) plans. Offered by private
                  insurance providers, Medicare Advantage plans offer all of the
                  coverage of Original Medicare (Parts A and B) plus additional
                  benefits that vary depending upon the provider and the
                  specific plan. Additional benefits of Medicare Advantage Plans
                  may include coverage of benefits not covered by Original
                  Medicare, including dental, vision, or hearing coverage.
                </ListItem>
                <ListItem className="leshen-list-item">
                  Medigap (Medicare Supplement) plans. Medigap policies are
                  designed to help with Medicare’s deductibles and other
                  out-of-pocket costs. Medigap policies are designed to work in
                  concert with Original Medicare and are not available for those
                  enrolled in a Medicare Advantage plan.
                </ListItem>
                <ListItem className="leshen-list-item">
                  Medicare Part D (prescription drug) plans. Medicare Part D
                  prescription drug coverage works in concert with Original
                  Medicare or a Medicare Advantage plan. There are many Medicare
                  Part D plans available—different plan options cover different
                  drugs, so be sure that when you enroll, you make sure your
                  needed prescriptions are covered by the plan you choose
                </ListItem>
                <ListItem className="leshen-list-item">
                  Dual Eligible Special Needs Plans (D-SNP or DSNP). If you are
                  eligible for both Medicare and state medical plan assistance
                  via Medicaid, you can enroll in a Dual Eligible Special Needs
                  Plan, abbreviated D-SNP or DSNP. DSNPs may cover the entire
                  premium cost of Medicare, depending on eligibility and other
                  factors.
                </ListItem>
              </List>
              <img
                src={cuteCouple}
                alt="cute elderly couple"
                style={{ marginBottom: '24px' }}
              />
              <Typography variant="h2">
                How do I enroll in a Medicare plan during AEP?
              </Typography>
              <Typography variant="body">
                Using a website like Healthcareplans.com can help you research
                and compare available Medicare Advantage, Medigap, Medicare Part
                D, and Medicare Dual Eligible Special Needs Plans. But whether
                you work with a private insurance provider or an agency like
                ours that collaborates with various providers to connect
                customers with multiple options from multiple providers, you’ll
                need to call or enroll online during the period between October
                15 and December 7. 
                <br />
                <br />
                Some of the benefits of working with Healthcareplans.com include
                the following:
              </Typography>
              <List style={{ marginBottom: '40px' }}>
                <ListItem>
                  US-based and US-licensed agents combine comprehensive Medicare
                  knowledge with excellent customer service you won’t find from
                  an overseas call center.
                </ListItem>
                <ListItem>
                  Plans are available from as many as eight national insurance
                  companies, allowing our agents to provide unbiased advice on
                  which plan is best for the customer.
                </ListItem>
                <ListItem>
                  Online plan browsing shows you exactly what’s available where;
                  and if you don’t want to wait on the phone during the busy
                  enrollment period, you can enroll online.
                </ListItem>
              </List>
              <Typography variant="h2">
                Do I have to re-enroll in a Medicare plan every year?
              </Typography>
              <Typography variant="body">
                The short answer is no. If you’re enrolled in Original Medicare
                and happy with your benefits, you’re good to go. The reason to
                make changes during the Annual Enrollment Period is if you need
                more or different coverage, especially the additional benefits
                provided by a Medicare Advantage (Part C), prescription drug
                (Part D), or Medigap plan.
              </Typography>
              <Typography variant="body">
                However, some private insurance providers occasionally retire
                plans. If you have a Medicare Advantage or other private insurer
                plan that will no longer exist in the following year, you’ll
                need to find a new policy to maintain coverage.
              </Typography>
              <img
                src={umbrellaCouple}
                alt="cute elderly couple"
                style={{ marginBottom: '24px' }}
              />
              <Typography variant="body">
                While we hope that this article has helped to provide all the
                information you need to know about Medicare AEP, the differences
                between AEP and OEP, and the different types of plans you can
                enroll in during AEP, sometimes it’s best to speak to an expert.
                Get in touch with our sales team by calling{' '}
                <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>. We’re
                especially happy to help you navigate the Annual Enrollment
                Period from October 15 - December 7 each year—during AEP we’re
                open seven days a week from 7 a.m. - 7 p.m.
              </Typography>
            </>
          }
          style={{
            color: '#1b0b54',
            paddingBottom: '0px',
          }}
        ></VariableContent>

        <VariableContent
          alignMainContent="left"
          backgroundColor="white"
          mainContent={
            <>
              <Typography variant="h2">Helpful resources:</Typography>
              <Link to="https://www.senior65.com/medicare/open-enrollment">
                https://www.senior65.com/medicare/open-enrollment
              </Link>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources
              </Link>
              <Link to="https://www.kff.org/medicare/issue-brief/what-to-know-about-the-medicare-open-enrollment-period-and-medicare-coverage-options/">
                https://www.kff.org/medicare/issue-brief/what-to-know-about-the-medicare-open-enrollment-period-and-medicare-coverage-options/ 
              </Link>
              <Link to="https://www.cms.gov/medicare/enrollment-renewal/special-needs-plans/dual-eligible">
                https://www.cms.gov/medicare/enrollment-renewal/special-needs-plans/dual-eligible 
              </Link>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan">
                https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan
              </Link>
            </>
          }
        />

        <SplitContent
          backgroundColor="light"
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={headsetLady}
              alt="Lady with computer and wearing headset"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Who We Are</Typography>
              <Typography variant="body">
                Collaborating with Clearlink Insurance Agency,
                Healthcareplans.com connects potential Medicare enrollees and
                current Medicare policyholders with information about enrollment
                periods, plans, and pricing.
                <br />
                <br /> To begin comparing plans available from our leading
                insurance partners, Get Started below.
              </Typography>
              <LinkButton
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
                color="primary"
                className="margin-x-auto"
                requestId={requestId}
              >
                Get Started
              </LinkButton>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor={null}
          customLink={null}
          className="brand-logos bg-lightgray"
        >
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            Our Insurance Brand Partners
          </Typography>
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <GlobalBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
